
import { defineComponent, computed, ref, Ref } from "vue";
import { usePagination, useRequest } from "vue-request";
import { format } from "date-fns";
import { message } from 'ant-design-vue';
import {
  TableState,
  TableStateFilters,
} from "ant-design-vue/es/table/interface";
import { /* EllipsisOutlined, DeleteOutlined,*/ EditOutlined } from "@ant-design/icons-vue";
import IconSVG from "@/components/IconSVG.vue";
import { permissionsColumns as columns } from "../_components/columns";
import FilterStatusMenu from "../_components/FilterStatusMenu.vue";
import PermissionModal from "../_components/PermissionModal.vue";
import { eLearningService } from "@/_services";
import { IPageResponse, IPermission, MenuInfo } from "@/_utils/types";
type Pagination = TableState["pagination"];

export default defineComponent({
  components: {
    IconSVG,
    // DeleteOutlined,
    EditOutlined,
    PermissionModal,
    FilterStatusMenu
  },
  setup() {
    const total = ref<number>(0);
    const visibleModal = ref<boolean>(false);
    const statusList = ref<boolean[]>([])
    const currentEditPermission = ref<IPermission>()
    const { data: dataSource, run, loading, current, pageSize } = usePagination(
      eLearningService.getPermissions,
      {
        defaultParams: [{ page: 1, size: 5 }],
        pagination: { pageSizeKey: "size", currentKey: "page" },
        formatResult: (result) => {
          console.log('result', result)
          //statusList.value = Array(pageSize.value).fill(false);
          const { data: tmp } = result.data as IPageResponse<IPermission[]>
          tmp.forEach((ele: IPermission) => {
            statusList.value?.push(ele.active ?? false)
          })
          total.value = result.data?.total ?? 0;
          return result.data?.data;
        },
      }
    );
    const pagination = computed(() => ({
      total: total.value,
      current: current.value,
      pageSize: pageSize.value,
      showSizeChanger: true,
      pageSizeOptions: ["5", "10", "20"],
      showTotal: (total: number, range: number[]) =>
        `${range[0]}-${range[1]} จาก ${total} รายการ`,
      buildOptionText: (size: Ref<number>) => {
        return `${size.value}`;
      },
    }));
    const handleTableChange = (
      pag: Pagination,
      filters: TableStateFilters,
      sorter: any
    ) => {
      run({
        size: pag?.pageSize ?? 5,
        ...(pag?.current && { page: pag.current }),
      });
    };
    const onSearch = (searchValue: string) => {
      run({
        name: searchValue,
        page: 1,
        size: pageSize.value,
      });
    };
    const addPermission = () => {
      currentEditPermission.value = undefined
      console.log("addPermission");
      visibleModal.value = true;
    };
    const confirmDelete = (idx: number) => {
      // message.success('Next step.');
      console.log("confirm!!", idx);
    };
    const handleClickEdit = (value: IPermission) => {
      currentEditPermission.value = value
      visibleModal.value = true;
    }
    const { data: updatedBigData, run: updateBigData } = useRequest(eLearningService.updatePermission, {
        manual: true,
        onSuccess:() => {
            console.log('updatedBigData', updatedBigData)
            loading.value = false
            if (updatedBigData.value?.success) {
              message.info('Updated permission successful');
            } else {
              message.error('Cannot update permission, please try again later');  
            }
        },
        onError: (error) => {
            console.log('error', error)
            loading.value = false
            message.error('Cannot update permission, please try again later');
        }
    })
    const onSwitch = (record: IPermission, val: boolean) => {
      console.log('onSwitch', record, val)
      loading.value = true
      const { id, permission_name_en, permission_name_th, active, permission_menu } = record
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      updateBigData(id!, {
        permission_name_th,
        permission_name_en,
        active: active ?? false,
        permission_menu
      })
    }

    const onCloseModal = () => {
      visibleModal.value = false
      run({
        page: 1,
        size: pageSize.value,
      });
    }

    const searchInput = ref<string>()
    const onClickMenuFilter = (key: number) => {
      searchInput.value = ''
      run({
        ...( key > 1 && {active : key == 3 ? 'false': 'true'} ),
        page: 1,
        size: pageSize.value,
      });
    };

    return {
      dataSource,
      pagination,
      loading,
      columns,
      handleTableChange,
      onSearch,
      addPermission,
      visibleModal,
      confirmDelete,
      format,
      statusList,
      handleClickEdit,
      currentEditPermission,
      onSwitch,
      onCloseModal,
      onClickMenuFilter,
      searchInput
    };
  },
});
